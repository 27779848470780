@import "@/styles/globals.scss";
@font-face {
  font-family: 'Futura-PT-Book';
  font-weight: 500;
  font-style: normal;
  src: url('fonts/Futura-PT-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Futura-PT-Book';
  font-weight: 500;
  font-style: italic;
  src: url('fonts/Futura-PT-Book-Oblique.woff2') format('woff2');
}

@font-face {
  font-family: 'HoeflerTitling';
  font-weight: 300;
  font-style: normal;
  src: url('fonts/HoeflerTitling-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'HoeflerTitling';
  font-weight: 300;
  font-style: italic;
  src: url('fonts/HoeflerTitling-LightItalic.woff2') format('woff2');
}
